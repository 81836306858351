import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Grid, Button, CircularProgress } from "@mui/material";
import { useAuthContext } from "../hooks/useAuthContext";
import { useSecureLink } from "../hooks/useSecureLink.js";
import { DATABASE_URL, ELL_URL } from "../appConstants";
import CryptoJS from "crypto-js";
import AuthHeader from "../services/AuthHeader";
import Axios from 'axios'

function Guidelines() {
  const { candidate } = useAuthContext();
  const { secureLink } = useSecureLink();
  const [fullTestStatus, setFullTestStatus] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    Axios.get(
      DATABASE_URL + "/candidate/check_full_tess_status/" + candidate.id,
      {
        headers: AuthHeader(),
      }
    ).then((res) => {
      setIsLoading(false)
      setFullTestStatus(res.data.status)
    });
  }, [])

  const handleNextStep = () => {
    if (candidate.type_of_test === 2 || candidate?.last_submit_question_type || fullTestStatus) {
      secureLink("/productive_skills_test");
    } else if (candidate.type_of_test === 1 || candidate.type_of_test === 3 || candidate.type_of_test === 4) {
      let configAddress =
        DATABASE_URL + "/auth/api_launch_config/" + candidate.id;

      let encryptedAddress = CryptoJS.AES.encrypt(
        configAddress,
        candidate.accessToken
      ).toString();

      let cookieText =
        "ellToken=" +
        candidate.accessToken +
        ";domain=.elltechnologies.com;path=/";
      document.cookie = cookieText;

      window.open(
        ELL_URL + encryptedAddress + "&token=" + candidate.accessToken,
        "_self"
      );
    }
  };
  return (
    <Layout>
      <h1>Test Guidelines</h1>
      {!isLoading ? (
        <>
          <ol className="test-guidelines-list">
            <li>Do not interact with any other candidate in the exam room. ​</li>
            <li>
              If you have a question, raise your hand and an invigilator will come
              to you.​
            </li>
            <li>Do not access any other webpages while you take the test. ​</li>
            <li>Do not leave your desk during the test. ​</li>
            <li>
              Your test will be monitored to ensure the validity of the results. ​
            </li>
          </ol>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleNextStep}
                className="button"
                style={{ marginTop: "60px" }}
              >
                I acknowledge
              </Button>
            </Grid>
          </Grid>
        </>
      ):(
        <ol className="circular-progress">
          <CircularProgress />
        </ol>
      )}
      
    </Layout>
  );
}

export default Guidelines;