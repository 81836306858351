import React from "react";
import CheckIcon from "@mui/icons-material/Check";

import "./TestProgress.css";

const TestProgress = ({ children, number }) => {
  const getNumberWithClass = () => {
    let content = [];
    for (let i = 1; i < 7; i++) {
      if (i < number) {
        content.push(<div key={i} className="cell finished"></div>);
      } else if (i === number) {
        content.push(<div key={i} className="cell current"></div>);
      } else {
        content.push(<div key={i} className="cell"></div>);
      }
    }
    return content;
  };

  return (
    <div>
      <div>{children}</div>
      <div className="test-progress-bar">
        <div className="cell-container">{getNumberWithClass()}</div>
        <h3>Task {number} of 6</h3>
      </div>
    </div>
  );
};

export default TestProgress;
