import React, { useState, useEffect } from "react";
import { useSecureLink } from "../hooks/useSecureLink.js";
import { Grid, Button } from "@mui/material";
import MicRecorder from "mic-recorder-to-mp3";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MicIcon from "@mui/icons-material/Mic";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useAuthContext } from "../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";

function SpeakingPractice() {
  const [open, setOpen] = useState(false);
  const [checkWarningOpen, setCheckWarningOpen] = useState(true);
  const [showQuestion, setShowQuestion] = useState(false);
  const [prepareTimer, setPrepareTimer] = useState(0);
  const [recordingTimer, setRecordingTimer] = useState(0);
  const [blobURL, setBlobURL] = useState("");
  const Mp3Recorder = new MicRecorder({ bitRate: 128 });
  const { secureLink } = useSecureLink();
  const navigate = useNavigate();
  const { dispatch } = useAuthContext();

  const id = React.useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
  };

  const handleStop = () => {
    localStorage.removeItem("candidate");
    dispatch({ type: "LOGOUT" });
    navigate("/login");
  };

  const handleContinue = () => {
    setOpen(false);
    setCheckWarningOpen(false);
    if (document.body.requestFullscreen) {
      document.body.requestFullscreen();
    } else if (document.body.webkitRequestFullscreen) {
      document.body.webkitRequestFullscreen();
    } else if (document.body.msRequestFullscreen) {
      document.body.msRequestFullscreen();
    }
  };

  useEffect(() => {
    if (document.body.requestFullscreen) {
      document.body.requestFullscreen();
    } else if (document.body.webkitRequestFullscreen) {
      document.body.webkitRequestFullscreen();
    } else if (document.body.msRequestFullscreen) {
      document.body.msRequestFullscreen();
    }

    if (window.addEventListener) {
      window.addEventListener("fullscreenchange", exitHandler, false);
      window.addEventListener("mozfullscreenchange", exitHandler, false);
      window.addEventListener("MSFullscreenChange", exitHandler, false);
      window.addEventListener("webkitfullscreenchange", exitHandler, false);
    }
  }, []);

  const exitHandler = () => {
    if (
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      setOpen(true);
    }
  };

  useEffect(() => {
    if (prepareTimer !== 0) {
      id.current = window.setInterval(() => {
        setPrepareTimer((time) => time - 1);
      }, 1000);
    } else {
      if (showQuestion) handleRecording();
      clear();
    }
    return () => clear();
  }, [prepareTimer]);

  useEffect(() => {
    if (recordingTimer !== 0) {
      id.current = window.setInterval(() => {
        setRecordingTimer((time) => time - 1);
      }, 1000);
    } else {
      clear();
    }
    return () => clear();
  }, [recordingTimer]);

  const handleRecording = () => {
    Mp3Recorder.start()
      .then(() => {
        setRecordingTimer(20);
      })
      .catch((e) => console.error(e));
    setTimeout(() => {
      Mp3Recorder.stop()
        .getMp3()
        .then(([buffer, blob]) => {
          setBlobURL(URL.createObjectURL(blob));
        })
        .catch((e) => console.log(e));
    }, 20 * 1000);
  };

  return (
    <>
      <Dialog
        open={checkWarningOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Warning!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are now in full-screen mode. Any attempt to exit full-screen
            mode may result in your test getting disqualified.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleContinue}
            autoFocus
            variant="contained"
            color="success"
          >
            Continue with test
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Warning!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do not attempt to leave this test window. You are required to remain
            in the test window until after you submit your test. Your test may
            get suspended if you attempt to leave again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStop} color="error">
            Stop test now
          </Button>
          <Button
            onClick={handleContinue}
            autoFocus
            variant="contained"
            color="success"
          >
            Continue with test
          </Button>
        </DialogActions>
      </Dialog>
      {!showQuestion ? (
        <div className="question-container">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ m: "auto" }}
          >
            <Grid item>
              <h2>Practice Task</h2>
            </Grid>
            <Grid item>
              <p>
                This task will NOT be graded. You can repeat this task as many
                times as you need. <br />
                You have 20 seconds to prepare and 20 seconds to respond.
              </p>
            </Grid>
            <Grid item sx={{ mt: "50px" }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className="button"
                onClick={() => {
                  setShowQuestion(true);
                  setPrepareTimer(20);
                  setRecordingTimer(0);
                }}
              >
                Start
              </Button>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className="question-container">
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            height={"100%"}
          >
            <Grid item xs={1}>
              <h4>Practice Task</h4>
            </Grid>
            <Grid item xs={1}>
              <h3>Describe your daily routine</h3>
            </Grid>
            <Grid item xs={8}>
              <div
                className={`time-circle ${!prepareTimer ? "red" : ""} ${
                  recordingTimer || prepareTimer ? "recording" : ""
                } ${blobURL ? "green" : ""}`}
              >
                <div>
                  {prepareTimer ? (
                    <>
                      <AccessTimeIcon />
                      <p>00:{("0" + prepareTimer).slice(-2)}</p>
                    </>
                  ) : (
                    ""
                  )}
                  {recordingTimer ? (
                    <>
                      <MicIcon />
                      <p>00:{("0" + recordingTimer).slice(-2)}</p>
                    </>
                  ) : (
                    ""
                  )}
                  {blobURL ? (
                    <PlayArrowIcon
                      onClick={() => {
                        const player =
                          document.getElementById("practice-audio");
                        if (player.paused) {
                          player.play();
                        } else {
                          player.pause();
                        }
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {prepareTimer ? <p className="prepare-timer">Prepare</p> : ""}
              {recordingTimer ? (
                <p className="recording-timer">Recording</p>
              ) : (
                ""
              )}
              {blobURL ? (
                <>
                  <p className="recording-completed">Completed</p>
                  {blobURL && (
                    <audio
                      id="practice-audio"
                      src={blobURL}
                      hidden
                      controls="controls"
                    />
                  )}
                </>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={1}>
              {blobURL ? (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    style={{ margin: "0 20px" }}
                    onClick={() => {
                      setPrepareTimer(10);
                      setRecordingTimer(0);
                      setBlobURL(null);
                    }}
                  >
                    Retry
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className="button"
                    onClick={() => {
                      secureLink("/productive_skills_test/speaking_test");
                    }}
                  >
                    Finish Practice
                  </Button>
                </>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

export default SpeakingPractice;
