import React from "react";
import Layout from "../components/Layout";
import { Grid, Button } from "@mui/material";
import { DATABASE_URL, ELL_URL } from "../appConstants";
import { useAuthContext } from "../hooks/useAuthContext";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";

function ReceptiveSkillsTest() {
  const { candidate } = useAuthContext();
  const navigate = useNavigate();

  const handleNextStep = () => {
    let configAddress =
      DATABASE_URL + "/auth/api_launch_config/" + candidate.id;

    let encryptedAddress = CryptoJS.AES.encrypt(
      configAddress,
      candidate.accessToken
    ).toString();

    let cookieText =
      "ellToken=" +
      candidate.accessToken +
      ";domain=.elltechnologies.com;path=/";
    document.cookie = cookieText;

    window.open(
      ELL_URL + encryptedAddress + "&token=" + candidate.accessToken,
      "_self"
    );
    // navigate("/productive_skills_test");
  };
  return (
    <Layout>
      <div className="welcome-page">
        <div className="welcome-page-left">
          <img src="/images/welcome-logo.png" alt="logo" />
        </div>
        <div className="welcome-page-right">
          <Grid
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="left"
          >
            <span>Part 1</span>
            <h1>Reading and Listening</h1>
            <ul>
              <li>
                You have <strong>50 min</strong> to complete this component.
              </li>
              <li>There will be 15 questions.</li>
              <li>
                You can change your answers as many times as you wish before
                submitting.
              </li>
              <li>Once you click “Start” below, the timer will start.</li>
            </ul>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className="button"
                onClick={handleNextStep}
              >
                Start
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Layout>
  );
}

export default ReceptiveSkillsTest;