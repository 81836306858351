import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { Grid, Button } from "@mui/material";
import { useOutlet } from "react-router-dom";
import { useSecureLink } from "../hooks/useSecureLink.js";
import { useAuthContext } from "../hooks/useAuthContext";

function ProductiveSkillsTest() {
  const outlet = useOutlet();
  const { secureLink } = useSecureLink();
  const { candidate } = useAuthContext();

  const handleNextStep = () => {
    if (
      candidate?.last_submit_question_type === 3 ||
      candidate?.last_submit_question_id === 4
    ) {
      secureLink("/productive_skills_test/writing_test");
    } else if (candidate?.last_submit_question_type === 2) {
      secureLink("/productive_skills_test/speaking_test");
    } else {
      secureLink("/productive_skills_test/speaking_practice");
    }
  };
  return (
    <Layout>
      {outlet || (
        <div className="description-page">
          <div className="description-page-left">
            <img src="/images/welcome-logo.png" alt="logo" />
          </div>
          <div className="description-page-right">
            <Grid
              container
              direction="column"
              justifyContent="space-evenly"
              alignItems="left"
            >
              <h1>Speaking and Writing</h1>
              <ul>
                <li>
                  You have <strong>70 min</strong> to complete this part.
                </li>
                <li>
                  There will be <strong>4 speaking tasks</strong> and{" "}
                  <strong>2 writing tasks</strong>.
                </li>
              </ul>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className="button"
                  onClick={handleNextStep}
                >
                  Start
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default ProductiveSkillsTest;
