import React, { useEffect, useState } from "react";
import TestProgress from "../../components/TestProgress/TestProgress";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress
} from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import { QUESTION_MAP, DATABASE_URL } from "../../appConstants";
import Axios from "axios";
import AuthHeader from "../../services/AuthHeader";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { useSecureLink } from "../../hooks/useSecureLink";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useLogin } from "../../hooks/useLogin";
import { DEMO_QUESTION_MAP } from "../../appConstants";
import { format } from "date-fns";

import "./WritingTest.css";

function WritingTest() {
  const { secureLink } = useSecureLink();
  const [open, setOpen] = useState(false);
  const [timesup, setTimesup] = useState(false);
  const [exitOpen, setExitOpen] = useState(false);
  const { logout } = useLogin();

  const { candidate } = useAuthContext();
  const [question, setQuestion] = useState();
  const [questionNumber, setQuestionNumber] = useState(5);
  const [timer, setTimer] = useState();
  const [answer, setAnswer] = useState();
  const [totalQues, setTotalQues] = useState([]);
  const [totalQuestionsCount, setTotalQuestionsCount] = useState(0)
  const [randomQuesGroup, setRandomQuesGroup] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const id = React.useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
  };

  const handleStop = () => {
    logout(candidate.id);
  };

  const handleContinue = () => {
    setExitOpen(false);
    if (document.body.requestFullscreen) {
      document.body.requestFullscreen();
    } else if (document.body.webkitRequestFullscreen) {
      document.body.webkitRequestFullscreen();
    } else if (document.body.msRequestFullscreen) {
      document.body.msRequestFullscreen();
    }
  };

  const exitHandler = () => {
    if (
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      setExitOpen(true);
    }
  };

  useEffect(() => {
    if (document.body.requestFullscreen) {
      document.body.requestFullscreen();
    } else if (document.body.webkitRequestFullscreen) {
      document.body.webkitRequestFullscreen();
    } else if (document.body.msRequestFullscreen) {
      document.body.msRequestFullscreen();
    }
  }, [question]);

  useEffect(() => {
    // if (candidate?.last_submit_question_id === 1) {
    //   setQuestionNumber(6);
    // }
    Axios.get(DATABASE_URL + "/question/select_random_writing_group_number", {
      headers: AuthHeader(),
    }).then((response) => {
      const ques_group = response.data.group_number
      const random_group = ques_group[Math.floor(Math.random() * ques_group.length)]
      setRandomQuesGroup(random_group.question_group_id)
      console.log('group............', random_group.question_group_id);
      Axios.get(DATABASE_URL + "/question/get_writing_questions_arr/" + random_group.question_group_id, {
        headers: AuthHeader(),
      }).then((response) => {
        const data = response.data.questions
        setTotalQues(data.filter((q) => q.id))
        console.log('total ques............', data.length);
        setTotalQuestionsCount(data.length)
      });

    })
    
    if (window.addEventListener) {
      window.addEventListener("fullscreenchange", exitHandler, false);
      window.addEventListener("mozfullscreenchange", exitHandler, false);
      window.addEventListener("MSFullscreenChange", exitHandler, false);
      window.addEventListener("webkitfullscreenchange", exitHandler, false);
    }
  }, []);

  useEffect(() => {
    if (timer !== 0) {
      id.current = window.setInterval(() => {
        setTimer((time) => time - 1);
      }, 1000);
    } else {
      submitAnswer();
      // debugger;
      setTimesup(true);
      clear();
    }
    return () => clear();
  }, [timer]);

  const getNextQuestion = (questionId) => {
    if (questionId) {
      console.log('question id..........', questionId);
      setIsLoading(true)
      Axios.get(`${DATABASE_URL}/question/writing/${candidate.type_of_test === 4 ? DEMO_QUESTION_MAP[questionId - 1]+'/group' : 'group/'+questionId}`, {
        headers: AuthHeader(),
      }).then((response) => {
        const q = response.data.question[0];
        setTimer(q.time);
        setQuestion(q);
        setIsLoading(false)
      });
    }
  };

  const handleNextStep = () => {
    setQuestionNumber((prev) => prev + 1);
    setTimesup(false);
    setAnswer("");

    if (questionNumber === 5 || questionNumber === 6) {
      getNextQuestion(questionNumber);
    } else {
      setQuestion("");
      secureLink("/survey");
    }
  };

  const handleEarlySubmit = () => {
    setOpen(false);
    submitAnswer();
    handleNextStep();
  };

  const submitAnswer = () => {
    const dataJSON = {
      candidate_id: candidate.id,
      question_id: question.id,
      question_type_id: 3,
      answer: answer,
      answer_time: format(Date.now(), "yyyy-MM-dd H:mm:ss"),
      test_center_id: candidate.test_center_id,
      test_type: candidate.type_of_test,
      examiner_id: candidate.writing_examiner_id
    };

    Axios.post(DATABASE_URL + "/grading", dataJSON, {
      // receive two parameter endpoint url ,form data
      headers: {
        Authorization: "Bearer " + candidate.accessToken,
      },
    }).then((res) => {
      // then print response status
      console.warn(res);
    });
  };

  return (
    <>
      <Dialog
        open={exitOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Warning!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do not attempt to leave this test window. You are required to remain
            in the test window until after you submit your test. Your test may
            get suspended if you attempt to leave again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStop} color="error">
            Stop test now
          </Button>
          <Button
            onClick={handleContinue}
            autoFocus
            variant="contained"
            color="success"
          >
            Continue with test
          </Button>
        </DialogActions>
      </Dialog>
      <div>
        {question ? (
          <>
            <div className="writing-test-wrapper">
              <div className="writing-test-heading">
                <h4>Task {questionNumber - 1}</h4>
                <h3>{question.description}</h3>
              </div>

              <div className={`writing-test-timer ${timer < 300 && "red"}`}>
                <AccessAlarmIcon
                  className="writing-test-timer-icon"
                  sx={{ fontSize: 30 }}
                />
                <span>
                  {("0" + Math.floor(timer / 60)).slice(-2) +
                    ":" +
                    ("0" + (timer % 60)).slice(-2)}
                </span>
              </div>
            </div>
            <div className="writing-test-input">
              <TextareaAutosize
                disabled={timer === 0}
                minRows={8}
                value={answer}
                onChange={(event) => {
                  setAnswer(event.target.value);
                  console.log(answer);
                }}
                placeholder={
                  "Write your full answer in " + question.word_limit + " words."
                }
                className="writing-test-textarea"
                maxLength={question.word_limit_in_char}
              />
              <span className="word-count">
                Total words:{" "}
                {answer.replace(/[\t\n\r\.\?\!]/gm, " ").split(" ").length > 1
                  ? answer.split(" ").filter((word) => {
                      if (word !== "") return word;
                    }).length
                  : 0}
              </span>
            </div>
            <Grid item className="center">
              {timer ? (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    setOpen(true);
                  }}
                  className="button"
                >
                  Submit
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleNextStep}
                  className="button"
                >
                  Next
                </Button>
              )}
              <Dialog
                open={timesup}
                onClose={() => {
                  setTimesup(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="writing-confirm-submit-wrapper"
              >
                <DialogContent>
                  <DialogContentText>
                    <p>Your Time is over</p>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Grid container justifyContent="center" spacing={3}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleNextStep}
                        className="button"
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </DialogActions>
              </Dialog>
              <Dialog
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="writing-confirm-submit-wrapper"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <span>Are you sure?</span> <br />
                    <span>Once you click on confirm you cannot make any changes</span>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Grid container justifyContent="center" spacing={3}>
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="large"
                        onClick={() => {
                          setOpen(false);
                        }}
                        autoFocus
                      >
                        <ClearIcon />
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={handleEarlySubmit}
                        variant="contained"
                        size="large"
                        color="error"
                        className="button"
                      >
                        <CheckIcon /> Confirm
                      </Button>
                    </Grid>
                  </Grid>
                </DialogActions>
              </Dialog>
            </Grid>

            <TestProgress number={questionNumber - 1} />
          </>
        ) : (
          <div className="question-container">
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ m: "auto" }}
            >
              {randomQuesGroup === '' || totalQuestionsCount === 0 ? (
                <CircularProgress />
              ):(
                <>
                  <Grid item>
                    <h2>Writing Test</h2>
                  </Grid>
                  <Grid item>
                    <p>
                      You will have 20 minutes on task 1 to plan and submit your
                      response.
                    </p>
                    <p>
                      You will have 40 minutes on task 2 to plan and submit your
                      response.
                    </p>
                  </Grid>
                  <Grid item sx={{ mt: "50px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleNextStep}
                      className="button"
                      disabled={isLoading}
                    >
                      {isLoading ? <CircularProgress size='1.5rem' /> : 'Start'}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        )}
      </div>
    </>
  );
}

export default WritingTest;
