import React, { useEffect, useState } from "react";
import TestProgress from "../../components/TestProgress/TestProgress";
import { Grid, Button, CircularProgress } from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../../hooks/useLogin";
import { DATABASE_URL } from "../../appConstants";
import Axios from "axios";
import AuthHeader from "../../services/AuthHeader";
import MicRecorder from "mic-recorder-to-mp3";
import CheckIcon from "@mui/icons-material/Check";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MicIcon from "@mui/icons-material/Mic";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { DEMO_QUESTION_MAP } from "../../appConstants";
import { format } from "date-fns";

import "./SpeakingTest.css";

function SpeakingTest() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const { candidate } = useAuthContext();
  const { logout } = useLogin();
  const [question, setQuestion] = useState();
  const [questionNumber, setQuestionNumber] = useState(1);
  const [blobURL, setBlobURL] = useState("");
  const Mp3Recorder = new MicRecorder({ bitRate: 128 });
  const [isLoading, setIsLoading] = useState(false)

  const [timer, setTimer] = useState(1);
  const [recordTimer, setRecordTimer] = useState(0);
  const [timeLimit, setTimeLimit] = useState({
    prepare: 50,
    recording: 60,
  });
  const id = React.useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
  };

  const handleStop = () => {
    logout(candidate.id);
  };

  const handleContinue = () => {
    setOpen(false);
    if (document.body.requestFullscreen) {
      document.body.requestFullscreen();
    } else if (document.body.webkitRequestFullscreen) {
      document.body.webkitRequestFullscreen();
    } else if (document.body.msRequestFullscreen) {
      document.body.msRequestFullscreen();
    }
  };

  useEffect(() => {
    if (document.body.requestFullscreen) {
      document.body.requestFullscreen();
    } else if (document.body.webkitRequestFullscreen) {
      document.body.webkitRequestFullscreen();
    } else if (document.body.msRequestFullscreen) {
      document.body.msRequestFullscreen();
    }
    Mp3Recorder.stop();
  }, [question]);

  const exitHandler = () => {
    if (
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      setOpen(true);
    }
  };

  useEffect(() => {
    // Axios.get(DATABASE_URL + "/question/select_random_speaking_group_number", {
    //   headers: AuthHeader(),
    // }).then((response) => {
    //   const ques_group = response.data.group_number
    //   const random_group = ques_group[Math.floor(Math.random() * ques_group.length)]
    //   console.log('group............', random_group.question_group_id);
    //   setRandomQuesGroup(random_group.question_group_id)
    //   console.log('speaking ques group...........', random_group.question_group_id);
      
    //   Axios.get(DATABASE_URL + "/question/get_speaking_questions_arr/" + random_group.question_group_id, { 
    //     headers: AuthHeader(),
    //   }).then((response) => {
    //     const data = response.data.questions
    //     setTotalQues(data.filter((q) => q.id))
    //     setTotalQuestionsCount(data.length)
    //   });
    // })
    
    if (window.addEventListener) {
      window.addEventListener("fullscreenchange", exitHandler, false);
      window.addEventListener("mozfullscreenchange", exitHandler, false);
      window.addEventListener("MSFullscreenChange", exitHandler, false);
      window.addEventListener("webkitfullscreenchange", exitHandler, false);
    }
    if (candidate?.last_submit_question_id) {
      setQuestionNumber(candidate.last_submit_question_id + 1);
    }
    window.addEventListener("beforeunload", alertUser);
    window.addEventListener("unload", handleEndConcert);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
      window.removeEventListener("unload", handleEndConcert);
      handleEndConcert();
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const handleEndConcert = async () => {
    console.log("EndConcert");
  };

  useEffect(() => {
    if (timer !== 0) {
      id.current = window.setInterval(() => {
        setTimer((time) => time - 1);
      }, 1000);
    } else {
      handleRecording();
      clear();
    }
    return () => clear();
  }, [timer]);

  useEffect(() => {
    if (recordTimer !== 0) {
      id.current = window.setInterval(() => {
        setRecordTimer((time) => time - 1);
      }, 1000);
    } else {
      clear();
    }
    return () => clear();
  }, [recordTimer]);

  const getNextQuestion = (questionId) => {
    if (questionId) {
      setIsLoading(true)
      Axios.get(`${DATABASE_URL}/question/speaking/${candidate.type_of_test === 4 ? DEMO_QUESTION_MAP[questionId - 1]+'/group/' : 'group/'+questionId}`, {
        headers: AuthHeader(),
      }).then((response) => {
        const q = response.data.question[0];
        setTimer(q.preparation_time);
        setTimeLimit({
          prepare: q.preparation_time,
          recording: q.time,
        });
        setQuestion(response.data.question);
        setIsLoading(false)
      });
    }
  };

  const handleNextStep = () => {
    setQuestionNumber((prev) => prev + 1);
    setRecordTimer(0);
    setBlobURL("");

    if (questionNumber < 5) {
      getNextQuestion(questionNumber);
    } else {
      console.log('going to writing...............');
      setQuestion("");
      navigate("/productive_skills_test/writing_test");
    }
  };

  const handleRecording = () => {
    if (question[0].id) {
      Mp3Recorder.start()
        .then(() => {
          setRecordTimer(timeLimit.recording);
        })
        .catch((e) => console.error(e));
    }

    setTimeout(() => {
      Mp3Recorder.stop()
        .getMp3()
        .then(([buffer, blob]) => {
          setBlobURL(URL.createObjectURL(blob));

          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader.result;

            const dataJSON = {
              candidate_id: candidate.id,
              question_id: question[0].id,
              question_type_id: 2,
              answer: base64data,
              answer_time: format(Date.now(), "yyyy-MM-dd H:mm:ss"),
              test_center_id: candidate.test_center_id,
              test_type: candidate.type_of_test,
              examiner_id: candidate.speaking_examiner_id
            };

            Axios.post(DATABASE_URL + "/grading", dataJSON, {
              headers: {
                Authorization: "Bearer " + candidate.accessToken,
              },
            }).then((res) => {
              console.warn(res);
            });
          };
        })
        .catch((e) => console.log(e));
    }, timeLimit.recording * 1000);
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Warning!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do not attempt to leave this test window. You are required to remain
            in the test window until after you submit your test. Your test may
            get suspended if you attempt to leave again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStop} color="error">
            Stop test now
          </Button>
          <Button
            onClick={handleContinue}
            autoFocus
            variant="contained"
            color="success"
          >
            Continue with test
          </Button>
        </DialogActions>
      </Dialog>
      {question ? (
        <>
          <div className="question-container">
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              height={"100%"}
            >
              <Grid item xs={1}>
                <h4>Task {questionNumber - 1}</h4>
              </Grid>
              <Grid item xs={1}>
                <h3>{question[0].description}</h3>
              </Grid>

              <Grid item xs={6}>
                <div
                  className={`time-circle ${!timer ? "red" : ""} ${
                    recordTimer || timer ? "recording" : ""
                  } ${blobURL ? "green" : ""}`}
                >
                  <div>
                    {timer ? (
                      <>
                        <AccessTimeIcon />
                        <p>
                          0{Math.floor(timer / 60)}:
                          {("0" + (timer % 60)).slice(-2)}
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                    {recordTimer && !blobURL ? (
                      <>
                        <MicIcon />
                        <p>
                          0{Math.floor(recordTimer / 60)}:
                          {("0" + (recordTimer % 60)).slice(-2)}
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                    {blobURL ? <CheckIcon /> : ""}
                  </div>
                </div>
                {timer ? <p className="prepare-timer">Prepare</p> : ""}
                {recordTimer && !blobURL ? (
                  <p className="recording-timer">Recording</p>
                ) : (
                  ""
                )}
                {blobURL ? (
                  <p className="recording-completed">Completed</p>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={1}>
                {blobURL && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={!blobURL}
                      onClick={handleNextStep}
                      className="button"
                    >
                      Next
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          </div>

          <TestProgress number={questionNumber - 1} />
        </>
      ) : (
        <div className="question-container">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ m: "auto" }}
          >
            {!questionNumber ? (
              <CircularProgress />
            ):(
              <> 
                <Grid item>
                  <h2>Speaking Test</h2>
                </Grid>
                <Grid>
                  <p className="p-span">
                    These tasks WILL be graded. You can only record each response
                    once.
                    <br />
                    <span className="p-span">
                      For tasks 1, 2 and 3 You have 50 seconds to prepare and 60
                      seconds to respond.
                    </span>
                    <br />
                    <span className="p-span">
                      For task 4 You have 50 seconds to prepare and 120 seconds to
                      respond.
                    </span>
                  </p>
                </Grid>
                <Grid item className="center" sx={{ mt: "50px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleNextStep}
                    className="button"
                    disabled={isLoading}
                  >
                    {isLoading ? <CircularProgress size='1.5rem' /> : 'Start'}
                  </Button>
                </Grid>
              </> 
            )}
          </Grid>
        </div>
      )}
    </>
  );
}

export default SpeakingTest;
