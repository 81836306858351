import React from "react";
import { Form } from "react-final-form";
import { Grid, Button, Alert } from "@mui/material";
import { TextField, makeValidate, makeRequired } from "mui-rff";
import * as Yup from "yup";
import { useLogin } from "../../hooks/useLogin";

import "./Login.css";

const schema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const validate = makeValidate(schema);

const required = makeRequired(schema);

const formFields = [
  <TextField
    label="Email"
    name="email"
    type="email"
    required={required.email}
    validate={validate.email}
  />,
  <TextField
    label="Password"
    name="password"
    type="password"
    required={required.password}
  />,
];

function Login() {
  const { login, error } = useLogin();

  const onSubmit = async (values) => {
    await login(values.email, values.password);
  };

  return (
    <div className="login-page">
      <div className="login-page-left">
        <h1>
          ELL
          <br />
          Assessment
          <br />
          Test
        </h1>
      </div>
      <div className="login-page-right">
        <img src="/images/ell-logo.png" alt="logo" />
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, submitting, form, values }) => (
            <form onSubmit={handleSubmit} className="login-form">
              <Grid container direction="column" spacing={4}>
                {formFields.map((field, index) => (
                  <Grid item key={index}>
                    {field}
                  </Grid>
                ))}
                {error && (
                  <Grid item>
                    <Alert severity="error">{error}</Alert>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={submitting}
                  >
                    LOGIN
                  </Button>
                </Grid>
              </Grid>
              {/* <pre>{JSON.stringify(values)}</pre> */}
            </form>
          )}
        />
        <div className="login-page-right-links">
          <a
            href="https://www.elltechnologies.com/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy{" "}
          </a>
          <br />
          <a
            href="https://www.elltechnologies.com/terms-and-conditions/"
            target="_blank"
            rel="noreferrer"
          >
            Terms & Conditions{" "}
          </a>
        </div>
      </div>
    </div>
  );
}

export default Login;
