// export const DATABASE_URL = "http://localhost:3000";
export const DATABASE_URL = window.location.hostname.indexOf("-stage") > -1 
? "https://certification-api-stage.elltechnologies.com" 
:  window.location.hostname.indexOf("-prod") > -1 ? "https://certification-api-prod.elltechnologies.com"  : "https://certification-api.elltechnologies.com";

export const USER_ROLES = {
  Administrator: 1,
  Proctor: 2,
  Examiner: 3,
};

export const ROLE = [];
ROLE[1] = "Administrator";
ROLE[2] = "Proctor";
ROLE[3] = "Examiner";

export const STATUS = ["Suspended", "Active"];
export const COMPLETED_TEST = ["No", "Yes"];

export const TYPE_OF_TEST = [];
TYPE_OF_TEST[1] = "Full Test";
TYPE_OF_TEST[2] = "Productive Skills Test";
TYPE_OF_TEST[3] = "Receptive Skills Test";

export const QUESTION_MAP = [];
QUESTION_MAP[1] = "speaking_q1";
QUESTION_MAP[2] = "speaking_q2";
QUESTION_MAP[3] = "speaking_q3";
QUESTION_MAP[4] = "speaking_q4";
QUESTION_MAP[5] = "writing_q1";
QUESTION_MAP[6] = "writing_q2";

export const DEMO_QUESTION_MAP = [1,2,3,4,1,8]

export const ELL_URL = window.location.hostname.indexOf("-stage") > -1 ? 
  "https://content-dev.elltechnologies.com/index.html?launch_config=" :
  "https://content.elltechnologies.com/index.html?launch_config=";

// in seconds
export const SPEAKING_TEST_SHORT_PREPARE_TIME = 75;

export const SPEAKING_TEST_SHORT_RECORDING_TIME = 60;

export const SPEAKING_TEST_LONG_PREPARE_TIME = 105;

export const SPEAKING_TEST_LONG_RECORDING_TIME = 120;

export const WRITING_TEST_TIME = 30 * 60;
