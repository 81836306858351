import React from "react";
import { Box, Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

function EquipmentCard({ children, step, title, button, onClick, permission }) {
  return (
    <div className="equipment-card">
      <div className={`equipment-card-icon ${permission ? "pass" : ""}`}>
        {permission ? <CheckIcon fontSize="large" /> : <span>{step}</span>}
      </div>
      <h2>{title}</h2>
      {children}
      <Box sx={{ p: 3 }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={onClick}
          className="button"
        >
          {button}
        </Button>
      </Box>
    </div>
  );
}

export default EquipmentCard;
