import React from "react";
import { useLocation } from "react-router";
import { LocationContext } from "../services/LocationContextProvider";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";

const PUBLIC_ALLOWED_ROUTES = [
  "/home",
  "/login",
  "/test_equipment",
  "/test_guidelines",
  "/productive_skills_test",
  "/productive_skills_test/writing_test",
];

export function useInvalidUrlRoutes() {
  const { dispatch } = useAuthContext();
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const { lastVisitedLocation } = React.useContext(LocationContext);
  // console.log(lastVisitedLocation.current, currentLocation);

  const validateUrl = () => {
    if (
      lastVisitedLocation.current === null &&
      !PUBLIC_ALLOWED_ROUTES.includes(currentLocation.pathname)
    ) {
      localStorage.removeItem("candidate");
      dispatch({ type: "LOGOUT" });
      navigate("/login");
      // alert("cannot go here!");
      throw new Error("Cannot come here directly!!");
    }
  };

  return { validateUrl };
}
