import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useLogin } from "../../hooks/useLogin";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { DATABASE_URL } from "../../appConstants";

import "./Header.css";

function Header() {
  const { candidate, dispatch } = useAuthContext();
  const { logout } = useLogin();
  const [open, setOpen] = useState(false);
  const [testing, setTesting] = useState(false);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    handleLogout();
  };

  const handleLogout = () => {
    let dataJSON = {
      test_center_id: candidate.test_center_id,
      test_type: candidate.type_of_test
    }
    Axios.post(DATABASE_URL + "/test_center/subtract_licenses_for_test_center", dataJSON, {
      headers: {
        Authorization: "Bearer " + candidate.accessToken,
      },
    }).then((res) => {
      logout(candidate.id);
      console.warn(res);
    });
  };

  const handleSimpleLogout = () => {
    console.log('handle simple logout........');
    localStorage.removeItem("candidate");
    dispatch({ type: "LOGOUT" });
    navigate("/login");
  };

  useEffect(() => {
    const host = window.location.origin;
    if (window.location.href.startsWith(host + "/productive_skills_test")) {
      setTesting(true);
    } else {
      setTesting(false);
    }
  }, [window.location.href]);

  return (
    <header className="header">
      <div className="header-logo">
        <a href="/">
          <img src="/images/ell-logo.png" alt="logo" />
        </a>
      </div>
      <h1>ELL Assessment Test</h1>
      <div className="header-login">
        <p className="header-name">{candidate?.name}</p>
        {testing ? (
          <Button
            onClick={handleClickOpen}
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#7976e6" }}
          >
            Finish Test
          </Button>
        ) : (
          <Button
            onClick={handleSimpleLogout}
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#7976e6" }}
          >
            Logout
          </Button>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Once you confirm finish test. You are not able to do the test
              again.
              <br />
              Are you sure you want finish the test?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirm} variant="contained" color="error">
              Finish The Test
            </Button>
            <Button onClick={handleClose} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </header>
  );
}

export default Header;
