import React from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Grid, Button } from "@mui/material";
import { useSecureLink } from "../../hooks/useSecureLink.js";
import { useLogin } from "../../hooks/useLogin";
import { useNavigate } from "react-router-dom";

import "./Home.css";
import { useEffect } from "react";

function Home() {
  const { candidate, dispatch } = useAuthContext();
  const { secureLink } = useSecureLink();
  const { logout } = useLogin();
  const navigate = useNavigate();

  const handleClick = () => {
    secureLink("/test_equipment");
  };

  useEffect(() => {
    console.log('checking test time..............', candidate);
    const lastTestTime = new Date(
      candidate?.last_submit_question_time
    ).getTime();
    console.log('last time.........', lastTestTime);
    const currentTime = new Date().getTime();
    console.log();
    if (!isNaN(lastTestTime) && currentTime > lastTestTime + 90 * 60 * 1000) {
      alert("Your test is finished!");
      logout(candidate.id);
      dispatch({ type: "LOGOUT" });
      navigate("/login");
    }
  }, []);

  return (
    <div className="welcome-page">
      <div className="welcome-page-left">
        <img src="/images/welcome-logo.png" alt="logo" />
      </div>
      <div className="welcome-page-right">
        <Grid
          container
          direction="column"
          justifyContent="space-evenly"
          alignItems="left"
        >
          <h1>Welcome to the ELL Assessment Test</h1>
          <p>Let’s make sure that your equipment is working properly.</p>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className="button"
              onClick={handleClick}
            >
              Test Your Equipment
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Home;
