import React, { useState } from "react";
import Layout from "../components/Layout";
import * as Yup from "yup";
import { Checkboxes, makeValidate, makeRequired } from "mui-rff";
import { Form } from "react-final-form";
import { Grid, Button, Dialog, DialogContent, CircularProgress } from "@mui/material";
import Webcam from "react-webcam";
import { useSecureLink } from "../hooks/useSecureLink.js";
import Axios from "axios";
import AuthHeader from "../services/AuthHeader";
import { DATABASE_URL } from "../appConstants";
import { useAuthContext } from "../hooks/useAuthContext";
import IconButton from "@mui/material/IconButton";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const schema = Yup.object().shape({
  terms1: Yup.boolean().oneOf([true], "Please accept the terms").required(),
  terms2: Yup.boolean().oneOf([true], "Please accept the terms").required(),
  terms3: Yup.boolean().oneOf([true], "Please accept the terms").required(),
  terms4: Yup.boolean().oneOf([true], "Please accept the terms").required(),
  terms5: Yup.boolean().oneOf([true], "Please accept the terms").required(),
});

const validate = makeValidate(schema);

const required = makeRequired(schema);

const formFields = [
  <Checkboxes
    name="terms1"
    required={required.terms1}
    data={{
      label:
        "I verify that I will personally complete this test without outside assistance of any kind. ",
      value: true,
    }}
  />,
  <Checkboxes
    name="terms2"
    required={required.terms2}
    data={{
      label:
        "I will not use any other resource (e.g., human, study material, notes, computer, cellphone.) to assist me in answering the test questions. ",
      value: true,
    }}
  />,
  <Checkboxes
    name="terms3"
    required={required.terms3}
    data={{
      label:
        "I will not disclose, reveal, copy or take pictures of the exam or share any details of the exam whatsoever with any other outside party.",
      value: true,
    }}
  />,
  <Checkboxes
    name="terms4"
    required={required.terms4}
    data={{
      label:
        "I acknowledge that I can only take this test once and that I must remain within the test window at all times.  ",
      value: true,
    }}
  />,
  <Checkboxes
    name="terms5"
    required={required.terms5}
    data={{
      label: (
        <>
          I accept the{" "}
          <a
            href="https://www.elltechnologies.com/privacy"
            target="_blank"
            rel="noreferrer"
            className="privacy-link"
          >
            privacy policy
          </a>
        </>
      ),
      value: true,
    }}
  />,
];

function Declaration() {
  const { secureLink } = useSecureLink();
  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const { candidate } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = () => {
    setOpen(true);
  };

  const submitImage = () => {
    setIsLoading(true)
    Axios.post(
      DATABASE_URL + "/candidate/image/" + candidate.id,
      { image: imageSrc },
      {
        headers: AuthHeader(),
      }
    ).then((res) => {
      console.warn(res);
      setIsLoading(false)
      secureLink("/test_guidelines");
    });
  };

  return (
    <Layout>
      <h1>Candidate Test Declaration</h1>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, submitting, form, values }) => (
          <form onSubmit={handleSubmit} className="declaration-form">
            {isLoading ? (
              <Grid container direction="column" spacing={2} className="circular-progress" >
                <CircularProgress />
              </Grid>
            ):(
              <Grid container direction="column" spacing={2}>
                {formFields.map((field, index) => (
                  <Grid item key={index}>
                    {field}
                  </Grid>
                ))}
                <Grid item className="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={submitting}
                    className="button"
                  >
                    Let’s verify your identity
                  </Button>
                </Grid>
              </Grid>
            )}
            {/* <pre>{JSON.stringify(values)}</pre> */}
          </form>
        )}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog"
      >
        <DialogContent>
          <p style={{ textAlign: "center" }}>Confirm your identity</p>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {imageSrc ? (
              <>
                <img src={imageSrc} alt="screenshot" />
                <IconButton
                  size="large"
                  style={{ marginLeft: "60px" }}
                  onClick={() => {
                    setOpen(false);
                    submitImage();
                  }}
                >
                  <CheckIcon color="success" fontSize="large" />
                </IconButton>
                <IconButton
                  size="large"
                  onClick={() => {
                    setImageSrc("");
                  }}
                >
                  <ClearIcon color="error" fontSize="large" />
                </IconButton>
              </>
            ) : (
              <Webcam width="100%" screenshotFormat="image/jpeg">
                {({ getScreenshot }) => (
                  <IconButton
                    size="large"
                    onClick={() => {
                      setImageSrc(getScreenshot());
                    }}
                  >
                    <CameraAltIcon fontSize="large" />
                  </IconButton>
                )}
              </Webcam>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </Layout>
  );
}

export default Declaration;
