export default function authHeader() {
  const candidate = JSON.parse(localStorage.getItem("candidate"));

  if (candidate && candidate.accessToken) {
    return { Authorization: "Bearer " + candidate.accessToken };
    // return{ "x-access-token": user.accessToken };
  } else {
    return {};
  }
}
