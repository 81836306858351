import React, { useEffect } from "react";
import "./App.css";
import * as Page from "./pages";
import { Route, Routes, Navigate } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import { useInvalidUrlRoutes } from "./hooks/useInvalidUrlRoutes";

function App() {
  const { candidate } = useAuthContext();
  const { validateUrl } = useInvalidUrlRoutes();

  if (!candidate) {
    return <Page.Login />;
  }

  const ProtectedRoute = ({ children }) => {
    validateUrl();
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    document.addEventListener("paste", (event) => event.preventDefault());

    return children;
  };

  return (
    <div className="App">
      <Routes>
        <Route
          path="login"
          element={!candidate ? <Page.Login /> : <Navigate to="/" />}
        />
        <Route index element={<Page.Home />} />
        <Route path="test_equipment" element={<Page.Equipment />} />
        <Route
          path="candidate_test_declaration"
          element={<Page.Declaration />}
        />
        <Route path="test_guidelines" element={<Page.Guidelines />} />
        <Route
          path="receptive_skills_test"
          element={<Page.ReceptiveSkillsTest />}
        />
        <Route
          path="productive_skills_test"
          element={<Page.ProductiveSkillsTest />}
        >
          <Route path="speaking_practice" element={<Page.SpeakingPractice />} />
          <Route
            path="speaking_test"
            element={
              <ProtectedRoute>
                <Page.SpeakingTest />
              </ProtectedRoute>
            }
          />
          <Route
            path="writing_test"
            element={
              <ProtectedRoute>
                <Page.WritingTest />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="survey" element={<Page.Survery />} />
      </Routes>
    </div>
  );
}

export default App;
