import React, { useState } from "react";
import Layout from "../../components/Layout";
import Webcam from "react-webcam";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import EquipmentCard from "./EquipmentCard";
import MicRecorder from "mic-recorder-to-mp3";
import { useSecureLink } from "../../hooks/useSecureLink.js";
import ReactWaves from "@dschoon/react-waves";

import "./Equipment.css";
import { useEffect } from "react";

function Equipment() {
  const [audioInPermissions, setAudioInPermissions] = useState(false);
  const [audioOutPermissions, setAudioOutPermissions] = useState(false);
  const [videoPermissions, setVideoPermissions] = useState(false);
  const [open, setOpen] = useState(false);
  const [speakerCheck, setSpeakerCheck] = useState(false);
  const [recordTimer, setRecordTimer] = useState(0);
  const [blobURL, setBlobURL] = useState("");
  const Mp3Recorder = new MicRecorder({ bitRate: 128 });
  const { secureLink } = useSecureLink();

  const checkAudioInPermissions = () => {
    const permissions = navigator.mediaDevices.getUserMedia({ audio: true });
    permissions.then((stream) => {
      setAudioInPermissions(true);
      Mp3Recorder.start()
        .then(() => {
          setBlobURL("");
          setRecordTimer(5);
        })
        .catch((e) => console.error(e));

      setTimeout(() => {
        Mp3Recorder.stop()
          .getMp3()
          .then(([buffer, blob]) => {
            setBlobURL(URL.createObjectURL(blob));
          })
          .catch((e) => console.log(e));
      }, 5000);
    });
  };
  const checkAudioOutPermissions = () => {
    setSpeakerCheck(!speakerCheck);
    setAudioOutPermissions(true);
  };
  const checkVideoPermissions = () => {
    const permissions = navigator.mediaDevices.getUserMedia({ video: true });
    permissions.then((stream) => {
      setVideoPermissions(true);
    });
  };

  const handleClick = () => {
    secureLink("/candidate_test_declaration");
  };

  useEffect(() => {
    let interval;
    if (recordTimer !== 0) {
      interval = setInterval(() => {
        setRecordTimer((time) => time - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [recordTimer]);

  return (
    <Layout>
      <h1>Test your Equipment</h1>
      <div className="equipment-cards-container">
        <EquipmentCard
          title="Camera"
          step="1"
          button="Check Camera"
          permission={videoPermissions}
          onClick={checkVideoPermissions}
        >
          {videoPermissions ? (
            <Webcam
              style={{
                borderRadius: "15px",
                maxWidth: "80%",
                maxHeight: "50%",
              }}
            />
          ) : (
            ""
          )}
        </EquipmentCard>
        <EquipmentCard
          title="Microphone"
          step="2"
          button="Check Microphone"
          permission={audioInPermissions}
          onClick={checkAudioInPermissions}
        >
          <>
            <div className={`time-circle ${recordTimer ? "recording" : ""}`}>
              <p>00:{recordTimer}</p>
            </div>
            {recordTimer ? <p>Say, 1, 2, 3</p> : ""}
          </>
        </EquipmentCard>
        <EquipmentCard
          title="Speaker"
          step="3"
          button={!speakerCheck ? "PLAY AUDIO" : "PAUSE"}
          permission={audioOutPermissions}
          onClick={checkAudioOutPermissions}
        >
          {!blobURL ? (
            <p>Please record an audio first</p>
          ) : (
            <ReactWaves
              audioFile={blobURL}
              className={"react-waves"}
              options={{
                barHeight: 2,
                cursorWidth: 0,
                height: 110,
                hideScrollbar: true,
                progressColor: "#4da64d",
                responsive: true,
                waveColor: "#D1D6DA",
              }}
              volume={1}
              zoom={1}
              playing={speakerCheck}
            />
          )}
        </EquipmentCard>
      </div>
      <Grid item className="center">
        {videoPermissions && audioInPermissions && audioOutPermissions ? (
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleClick}
            className="button"
          >
            Next
          </Button>
        ) : (
          <>
            <Button
              variant="contained"
              color="error"
              size="large"
              onClick={() => {
                setOpen(true);
              }}
            >
              Trouble shoot
            </Button>
            <Dialog
              open={open}
              onClose={() => {
                setOpen(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle>Troubleshooting</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <ul>
                    <li>Check your hardware connection</li>
                    <li>Restart your browser</li>
                    <li>Fully reset your browser</li>
                    <li>Restart your device</li>
                    <li>Update your hardware driver</li>
                  </ul>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Grid>
    </Layout>
  );
}

export default Equipment;
