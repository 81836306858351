import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { DATABASE_URL } from "../appConstants";
import axios from "axios";

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { dispatch } = useAuthContext();

  const login = async (email, password) => {
    setIsLoading(true);
    setError(null);

    axios
      .post(
        DATABASE_URL + "/auth/candidate",
        {
          email,
          password,
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.accessToken) {
          // save the user to local storage
          const item = {
            ...response.data,
            expiry: Date.now() + 3 * 60 * 60 * 1000,
          };
          localStorage.setItem("candidate", JSON.stringify(item));

          // update the auth context
          dispatch({ type: "LOGIN", payload: response.data });

          // update loading state
          setIsLoading(false);
        }
      })
      .catch((error) => {
        const status = error.response.status;
        setIsLoading(false);
        if (status === 0) {
          setError("No Server Response");
        } else if (status === 401) {
          setError("Wrong username or password");
        } else {
          setError("Something wrong");
        }

        if (error.response.data.error) {
          setError(error.response.data.error);
        }
      });
  };

  const logout = (id) => {
    axios
      .get(DATABASE_URL + "/auth/candidate/logout/" + id)
      .then((response) => {
        localStorage.removeItem("candidate");
        dispatch({ type: "LOGOUT" });
      });
  };

  return { login, logout, isLoading, error };
};
