import React from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useLogin } from "../hooks/useLogin";
import { useAuthContext } from "../hooks/useAuthContext";
import { useEffect } from "react";
import axios from "axios";
import { DATABASE_URL } from "../appConstants";

function Survery() {
  const { candidate } = useAuthContext();
  const { logout } = useLogin();
  const [open, setOpen] = React.useState(false);
  const [finishTest, setFinishTest] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFinishTest(true);
  };

  const handleLogout = () => {
    let dataJSON = {
      test_center_id: candidate.test_center_id,
      test_type: candidate.type_of_test
    }
    axios.post(DATABASE_URL + "/test_center/subtract_licenses_for_test_center", dataJSON, {
      headers: {
        Authorization: "Bearer " + candidate.accessToken,
      },
    }).then((res) => {
      logout(candidate.id);
      console.warn(res);
    });
  };

  useEffect(() => {
    closeFullscreen();
    axios.get(DATABASE_URL + "/auth/candidate/logout/" + candidate.id);
  }, []);

  function closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  }

  return (
    <div className="welcome-page">
      <div className="welcome-page-left">
        <img src="/images/welcome-logo.png" alt="logo" />
      </div>
      <div className="welcome-page-right">
        <h1>
          Congratulations!
          <br /> You have completed the ELL Assessment Test
        </h1>
        <p>
          To help us improve your testing experience, please take a quick
          survey.
        </p>
        <Grid item>
          {!finishTest ? (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className="button"
              onClick={handleClickOpen}
            >
              Take the Survey
            </Button>
          ) : (
            <Button
              variant="contained"
              color="error"
              size="large"
              onClick={handleLogout}
            >
              Finish Test
            </Button>
          )}
          <Dialog
            fullWidth
            maxWidth="md"
            open={open}
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogContent>
              <iframe
                width="100%"
                height="600px"
                src="https://forms.office.com/r/uBPVwcWMcs?embed=true"
                frameBorder="0"
                marginWidth="0"
                marginHeight="0"
                allowFullScreen
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                msallowfullscreen="true"
              ></iframe>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </div>
    </div>
  );
}

export default Survery;
