import React from "react";
import { LocationContext } from "../services/LocationContextProvider";
import { useNavigate } from "react-router-dom";

export const useSecureLink = () => {
  const { registerLocation } = React.useContext(LocationContext);
  const navigate = useNavigate();

  const secureLink = (url) => {
    registerLocation(url);
    navigate(url);
    console.log(url);
  };

  return { secureLink };
};
